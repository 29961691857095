<div>
  <br>
  <h2 class="h3-tipografia-govco">Trámite: Registro y autorización de títulos en el área de la salud.</h2>
  <br>
  <p class="text2-tipografia-govco" style="text-align: justify"    >
    El propósito de este trámite es obtener autorización para el ejercicio de la profesión y ocupación, válida
    para todo el territorio nacional, en el área de la salud como técnico, tecnólogo, o universitario, de los
    títulos obtenido en la Ciudad de Bogotá D.C, excepto convalidaciones de titulación Extranjera.
    Nota: Las profesiones exceptuadas son: Medicina, Jefe de Enfermería, bacteriología, químicos farmacéuticos,
    fisioterapia, fonoaudiología, instrumentadores quirúrgicos, optometría, terapia respiratoria, odontología,
    nutrición y dietética y terapia ocupacional. Lo anterior teniendo en cuenta que el registro y autorización de
    título de dichas profesiones, fue delegada a sus respectivos cuerpos colegiados.</p>


  <br>
  <div>
    <h4 class="h4-tipografia-govco title-1" >Datos de la solicitud</h4>
    <label for="numero-radicado">*Campos Obligatorios</label>
  </div>

  <br>

  <div class="row">


    <div class="col-sm-12 col-md-6">
      <div class="entradas-de-texto-govco">
        <label for="numero-radicado">Número de radicado<span class="required"> *</span></label>
        <input type="text" id="numero-radicado"
               disabled [value]="infoRequest.filed" aria-describedby="filedNumber-note"/>
      </div>
    </div>

    <div class="col-sm-12 col-md-6">
      <div class="entradas-de-texto-govco">
        <label for="tipo-tramite">Tipo de trámite<span class="required"> *</span></label>
        <input type="text" id="tipo-tramite"
               disabled [value]="infoRequest.titleType" aria-describedby="titleTypeId-note"/>
      </div>
    </div>


    <form [formGroup]="clarificationForm">
      <div class="col-sm-12 col-md-7">
        <div class="entradas-de-texto-govco">
          <label for="motivoId">Motivo<span class="required"> *</span></label>
          <select class="form-select" id="motivoId" aria-label="Default select example"
                  formControlName="reasonTypeId" aria-describedby="reasonTypeId-note"
                  [ngClass]="{'error': isTouchedField(clarificationForm, 'reasonTypeId')}"
          >
            <option value="" selected disabled>Seleccione</option>
            <option value="14">Recurso de reposición</option>
            <option value="15">Solicitud de aclaración</option>
          </select>
          <span
            class="error-texto-govco alert-entradas-de-texto-govco" role="alert" aria-live="assertive">
          {{ getErrorMessage('reasonTypeId') }}
        </span>
        </div>
      </div>

      <div class="col-6" style="padding-top: 2%">
        <label for="inputSupportClarification" class="label-carga-de-archivo-govco">Soporte de recurso de reposición ó
          solicitar aclaración<span class="required"> *</span></label>
        <div class="input-group mt-2 mb-1 ">
          <input type="file" class="form-control input-file-custom" id="inputSupportClarification"
                 aria-describedby="inputFile_Aria" aria-label="Upload" [nz-tooltip]="'Peso máximo permitido: 10 MB'"
                 [ngClass]="{'error': isValidInputFile('inputSupportClarification')}"
                 accept="application/pdf" (change)="addSelectedFile($event, 'inputSupportClarification')">
          <button class="btn btn-outline-secondary" type="button" id="inputDocAddDescri_Clarification"
                  *ngIf="hasDocument()"
                  (click)="removeFileAdded('inputSupportClarification')">
            <span class="govco-trash-alt"></span>
          </button>
          <span >Peso máximo permitido: 10 MB</span>
        </div>
        <span class="text-validation-carga-de-archivo-govco"><small>Únicamente archivo en formato .pdf.</small></span>
        <br>
        <span *ngIf="isValidInputFile('inputSupportClarification')"
              class="error-texto-govco alert-entradas-de-texto-govco" id="titleTypeId-note" role="alert"
              aria-live="assertive" style="color: #962423">
          Es requerido
        <br>
        <br>
      </span>
      </div>

      <div class="col-sm-12 col-md-9">
        <div class="entradas-de-texto-govco">
          <label for="Observaciones" class="form-label">Observaciones del recurso de reposición ó solicitar aclaración
            <span class="required">*</span></label>
          <div class="container-input-texto-govco">
                      <textarea class="form-control" rows="3" id="Observaciones" aria-invalid="true"
                                aria-describedby="Observaciones-note"
                                formControlName="observation"
                                [ngClass]="{'error': isTouchedField(clarificationForm, 'observation')}"
                                placeholder="Observaciones"> </textarea>
            <span
              class="error-texto-govco alert-entradas-de-texto-govco" id="observations-note" role="alert"
              aria-live="assertive">
                      {{ getErrorMessage('observation') }}
                    </span>
          </div>
        </div>
      </div>
    </form>
  </div>

</div>
