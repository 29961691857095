<div class="d-flex flex-column general-layout">
  <div class="content-layout" id="content-layout-id">
    <main class="scroll" id="interno">
      <div class="information d-flex justify-content-center  ">


        <div class="govco-form-signin col-lg-6 col-md-6 col-12 my-4 ">
          <div class="mi-caja mt-3">
            <form [formGroup]="loginForm">
              <br>
              <br>
              <br>
              <h3 class="govco-title-sign-form headline-l-govco " style="text-align: center; font-size: 30px">
                Inicio de sesión
              </h3>
              <div class="row mt-3">
                <div class="d-flex mt-2" style="display: flex; align-items: center; justify-content: center;">

                  <button type="submit"
                          (click)="submitLoginFormB2C()"
                          class="btn-govco fill-btn-govco">INICIAR SESIÓN
                  </button>

                </div>
              </div>


            </form>
            <br>
            <br>
          </div>
        </div>
      </div>
    </main>
  </div>
</div>


