import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {environment} from "../../../environments/env.develop";
const { PROCEDURE_SHARED_URI } = environment;
const { PROCEDURE_SECURITY_URI } = environment;
const { PROCEDURE_NOTIFICATIONS_URI } = environment;
const { PROCEDURE_LOCAL_URI } = environment;

/**
 * Service con los métodos relacionados al registro de una persona
 */
@Injectable({
  providedIn: 'root'
})
export class TrackingService {

  constructor(private http: HttpClient) { }

  /**
   * Inserta el seguimiento de la solicitud
   * @param tracking
   */
  addTracking(tracking: any) : Observable<any> {
    return this.http.post(`${PROCEDURE_LOCAL_URI}/Tracking/AddTracking`,tracking);
  }

  /**
   * Obtiene el seguimiento de una solicitud
   * @param id
   */
   getTrackingbyid(id: string) : Observable<any> {
    return this.http.get(`${PROCEDURE_LOCAL_URI}/Tracking/GetTracking/${id}`);
  }

  /**
   * Obtiene las solicitudes con el mismo numero de id
   * @param id
   */
  getDuplicatedbyid(id: string) : Observable<any> {
    return this.http.get(`${PROCEDURE_LOCAL_URI}/Tracking/GetDuplicated/${id}`);
  }

  /**
   * Obtiene las solicitudes con el mismo numero de id
   * @param id
   */
  getValidationDocument(id: string) : Observable<any> {
    return this.http.get(`${PROCEDURE_LOCAL_URI}/Tracking/GetValidation/${id}`);
  }

  /**
   * Obtiene las solicitudes con el mismo numero de id
   * @param id
   */
  getRemainingdays(fecha:any ,id: string) : Observable<any> {
    return this.http.get(`${PROCEDURE_SHARED_URI}/v1/Holiday/CalculateRemainingDays/${fecha}/${id}`);
  }
  /**
   * Obtiene las solicitudes con el mismo numero de id
   * @param id
   */
  getRemainingdayslista(fechas:any ) : Observable<any> {
    return this.http.post(`${PROCEDURE_SHARED_URI}/v1/Holiday/CalculateRemainingDaysList`,fechas);
  }
  /**
   * Obtiene las fecha tentativa en la que se realizo la solcicitud de acuerdo a los dias que deben de haber pasado ingresado
   * por el usuario
   * @param id
   */
  getCreationDate(dias:any,id: string ) : Observable<any> {
    return this.http.get(`${PROCEDURE_SHARED_URI}/v1/Holiday/CalculateDateCreation/${dias}/${id}`);
  }
}
