<!-- Footer -->
<div class="govco-footer text2-tipografia-govco" style="margin-top: 5%;">
  <div class="govco-data-front">
    <div class="govco-footer-text">
      <div class="row govco-nombre-entidad">
        <div class="col-xs-12 col-lg-6">
          <p class="govco-text-header-1">Secretaría Distrital de Salud</p>
        </div>
        <div class="col-xs-12 col-lg-4 govco-logo-div-a">

          <img src="./assets/images/logoSDS.png" alt="Secretaria Distrital de Salud" style="margin-top: -10px">
        </div>
      </div>

      <div class="row col-xs-12 col-lg-7 govco-texto-sedes">
        <p class="govco-text-header-2">Sede principal</p>
        <p class="text3-tipografia-govco">
          Dirección: Cra 32 #12 - 81 <br class="govco-mostrar">
          Bogotá, Colombia. <br>
          Código Postal: 0571 <br>
          Horario de atención: <a style="text-decoration: underline"
                                  href="http://www.saludcapital.gov.co/Documents/Horarios_Atencion.pdf"
                                  target="_blank" rel="noopener">Consulte aquí los horarios</a> <br>
          Teléfono conmutador: (601) 364 90 90 <br>
        </p>
      </div>

      <div class="row col-xs-12 col-lg-7 govco-network">
        <div class="govco-iconContainer">
          <span class="icon govco-twitter-square"></span>
          <span class="govco-link-modal">
            <a href="https://twitter.com/SectorSalud" target="_blank" rel="noopener">Twitter
            </a>
          </span>
        </div>
        <div class="govco-iconContainer">
          <span class="icon govco-facebook-square"></span>
          <span class="govco-link-modal">
            <a href="https://www.facebook.com/secretariadistritaldesalud/" target="_blank" rel="noopener">Facebook
            </a>
          </span>
        </div>
        <div class="govco-iconContainer">
          <span class="icon govco-youtube-square"></span>
          <span class="govco-link-modal">
            <a href="https://www.youtube.com/@SecretariaDistritaldeSalud" target="_blank" rel="noopener">Youtube
            </a>
          </span>
        </div>
        <div class="govco-iconContainer">
          <span class="icon govco-instagram-square"></span>
          <span class="govco-link-modal">
            <a href="https://www.instagram.com/sectorsalud" target="_blank" rel="noopener">Instagram
            </a>
          </span>
        </div>
      </div>

      <div class="row govco-links-container">
        <div class="govco-link-container mt-2">
          <a class="govco-link-modal govco-link-modal-bold" href="http://www.saludcapital.gov.co/Documents/Politicas_Sitios_Web.pdf" target="_blank" rel="noopener">Políticas</a>
          <a class="govco-link-modal govco-link-modal-bold" href="http://www.saludcapital.gov.co/Documents/Politicas_Sitios_Web.pdf" target="_blank" rel="noopener">Términos y condiciones</a>
        </div>
      </div>
    </div>
  </div>

  <div class="govco-footer-logo">
    <div class="govco-logo-container">
      <span class="govco-co"></span>
      <span class="govco-separator"></span>
      <span class="govco-logo"></span>
    </div>
  </div>
</div>
<!-- Footer -->

