<form role="form" [formGroup]="requestDataForm">

  <div class="row">

    <div class="col-sm-12 col-md-12">
      <div class="entradas-de-texto-govco">
        <label for="tipoTituloId">Origen Título <span class="required">*</span></label>
        <select class="form-select" id="tipoTituloId" aria-label="Default select example"
                formControlName="titleTypeId" aria-describedby="titleTypeId-note"
                [ngClass]="{'error': isTouchedField(requestDataForm, 'titleTypeId')}"
                (change)="showFormNationalOrInternational()"
        >
          <option value="" selected disabled>Seleccione</option>
          <option value="1">NACIONAL</option>
          <option value="2">EXTRANJERO</option>
        </select>
        <span
          class="error-texto-govco alert-entradas-de-texto-govco" role="alert" aria-live="assertive">
          {{ getErrorMessage('titleTypeId') }}
        </span>
      </div>
    </div>

    <div class="col-sm-12 col-md-6" *ngIf="showInternationalForm == true">
      <div class="entradas-de-texto-govco">
        <label for="nombre-uni-internacional">Nombre Universidad Internacional <span class="required"> *</span></label>
        <input type="text" id="nombre-uni-internacional" placeholder="Nombre universidad internacional"
               style="text-transform: uppercase;"
               formControlName="nameInternationalUniversity" aria-describedby="nameInternationalUniversity-note"
               [ngClass]="{'error': isTouchedField(requestDataForm, 'nameInternationalUniversity')}"/>
        <span
          class="error-texto-govco alert-entradas-de-texto-govco" role="alert" aria-live="assertive">
          {{ getErrorMessage('nameInternationalUniversity') }}
        </span>
      </div>
    </div>

    <div class="col-sm-12 col-md-6 col-lg-6" *ngIf="showInternationalForm == false">
      <div class="entradas-de-texto-govco">
        <label for="institucionId">Institución Educativa <span class="required"> *</span></label>
        <ng-select formControlName="instituteId" id="institucionId" [nz-tooltip]="this.requestDataForm.get('instituteName').value"
                   aria-describedby="titleTypeId-note" aria-label="Default select example"
                   [ngClass]="{'error': isTouchedField(requestDataForm, 'instituteId')}"
                   (change)="this.getPrograms()"
        >
          <ng-option value="" selected disabled>Seleccione</ng-option>
          <ng-option *ngFor="let institute of listInstitutes"   [value]="institute.idInstitute">{{institute.nameinstitute}}</ng-option>
        </ng-select>
        <span class="error-texto-govco alert-entradas-de-texto-govco" role="alert" aria-live="assertive">
          {{ getErrorMessage('instituteId') }}
        </span>
      </div>
    </div>

    <div class="col-sm-12 col-md-6" *ngIf="showInternationalForm != null">
      <div class="entradas-de-texto-govco">
        <label for="profesionId">{{showInternationalForm ? 'Título Equivalente' : 'Profesión'}}<span class="required"> *</span></label>
        <ng-select id="profesionId" aria-label="Default select example" [nz-tooltip]="this.requestDataForm.get('professionName').value"
                formControlName="professionId"
                [ngClass]="{'error': isTouchedField(requestDataForm, 'professionId')}"
                (change)="activeProfesionalCard()"
        >
          <ng-option value="" selected disabled>Seleccione</ng-option>
          <ng-option *ngFor="let profession of listProfessions" [value]="profession.idsniesprogram">{{profession.programname}}</ng-option>
        </ng-select>
        <span
          class="error-texto-govco alert-entradas-de-texto-govco" role="alert" aria-live="assertive">
          {{ getErrorMessage('professionId') }}
        </span>
      </div>
    </div>

    <div class="col-sm-12 col-md-6" *ngIf="showInternationalForm == true">
      <div class="entradas-de-texto-govco">
        <label for="pais-origen">País Origen Título<span class="required"> *</span></label>
        <ng-select id="pais-origen" aria-label="Default select example"
                formControlName="countryId"
                [ngClass]="{'error': isTouchedField(requestDataForm, 'countryId')}"
        >
          <ng-option value="" selected disabled>Seleccione</ng-option>
          <ng-option *ngFor="let pais of listCountries" [value]="pais.idPais">{{pais.nombre}}</ng-option>
        </ng-select>
        <span
          class="error-texto-govco alert-entradas-de-texto-govco" role="alert" aria-live="assertive">
          {{ getErrorMessage('countryId') }}
        </span>
      </div>
    </div>

    <div class="col-sm-12 col-md-6" *ngIf="showInternationalForm == true">
      <div class="entradas-de-texto-govco">
        <label for="numero-convalidacion">Número de Resolución de Convalidación<span class="required"> *</span></label>
        <input type="text" id="numero-convalidacion" placeholder="Número de resolución de convalidación"
               style="text-transform: uppercase;"
               formControlName="numberResolutionConvalidation" aria-describedby="numero-convalidacion-note"
               [ngClass]="{'error': isTouchedField(requestDataForm, 'numberResolutionConvalidation')}"/>
        <span
          class="error-texto-govco alert-entradas-de-texto-govco" role="alert" aria-live="assertive">
          {{ getErrorMessage('numberResolutionConvalidation') }}
        </span>
      </div>
    </div>

    <div class="col-sm-12 col-md-6" *ngIf="showInternationalForm == false">
      <div class="entradas-de-texto-govco">
        <label for="diplomaNumero">Diploma No. </label>
        <input type="text" id="diplomaNumero" placeholder="Diploma No."
               style="text-transform: uppercase;"
               formControlName="diplomaNumber" aria-describedby="diplomaNumber-note"
               [ngClass]="{'error': isTouchedField(requestDataForm, 'diplomaNumber')}"/>
        <span
          class="error-texto-govco alert-entradas-de-texto-govco" role="alert" aria-live="assertive">
          {{ getErrorMessage('diplomaNumber') }}
        </span>
      </div>
    </div>


    <div class="col-sm-12 col-md-6" *ngIf="showInternationalForm == false">
      <div class="entradas-de-texto-govco">
        <label for="actaGrado">Acta de Grado </label>
        <input type="text" id="actaGrado" placeholder="Acta de grado"
               style="text-transform: uppercase;"
               formControlName="graduationCertificate" aria-describedby="graduationCertificate-note"
               [ngClass]="{'error': isTouchedField(requestDataForm, 'graduationCertificate')}"/>
        <span
          class="error-texto-govco alert-entradas-de-texto-govco" role="alert" aria-live="assertive">
          {{ getErrorMessage('graduationCertificate') }}
        </span>
      </div>
    </div>

    <div class="col-sm-12 col-md-6" *ngIf="showInternationalForm == true">
      <div class="entradas-de-texto-govco">
        <label for="fecha-resolucion-convalidacion">Fecha de Resolución <span class="required">*</span> </label>
        <input type="date" id="fecha-resolucion-convalidacion"
               formControlName="dateResolutionConvalidation"
               style="text-transform: uppercase;"
               (click)="blockCalendarFutureDate('fecha-resolucion-convalidacion')"
               [ngClass]="{'error': isTouchedField(requestDataForm, 'dateResolutionConvalidation') }"
        />
        <span *ngIf="!isValidField(requestDataForm, 'dateResolutionConvalidation')"
              class="error-texto-govco alert-entradas-de-texto-govco"  role="alert"
              aria-live="assertive">
          {{ getErrorMessage('dateResolutionConvalidation') }}
        </span>
      </div>
    </div>

    <div class="col-sm-12 col-md-6" *ngIf="showInternationalForm == true">
      <div class="entradas-de-texto-govco">
        <label for="entidadId">Entidad <span class="required">*</span></label>
        <select class="form-select" id="entidadId" aria-label="Default select example"
                formControlName="entityId" aria-describedby="titleTypeId-note"
                [ngClass]="{'error': isTouchedField(requestDataForm, 'entityId')}"
        >
          <option value="" selected disabled>Seleccione</option>
          <option value="1">MINISTERIO DE EDUCACIÓN</option>
          <option value="2">ICFES</option>
        </select>
        <span
          class="error-texto-govco alert-entradas-de-texto-govco" role="alert" aria-live="assertive">
          {{ getErrorMessage('entityId') }}
        </span>
      </div>
    </div>

    <div class="col-sm-12 col-md-6" *ngIf="showInternationalForm != null">
      <div class="entradas-de-texto-govco">
        <label for="fecha-terminacion">Fecha Terminación <span class="required">*</span> </label>
        <input type="date" id="fecha-terminacion"
               formControlName="endDate"
               style="text-transform: uppercase;"
               (click)="blockCalendarFutureDate('fecha-terminacion')"
               [ngClass]="{'error': isTouchedField(requestDataForm, 'endDate') }"
        />
        <span *ngIf="!isValidField(requestDataForm, 'endDate')"
              class="error-texto-govco alert-entradas-de-texto-govco"  role="alert"
              aria-live="assertive">
          {{ getErrorMessage('endDate') }}
        </span>
      </div>
    </div>

    <div class="col-sm-12 col-md-6" *ngIf="showInternationalForm == false">
      <div class="entradas-de-texto-govco">
        <label for="libro">Libro </label>
        <input type="text" id="libro" placeholder="Libro"
               style="text-transform: uppercase;"
               formControlName="book" aria-describedby="book-note"
               [ngClass]="{'error': isTouchedField(requestDataForm, 'book')}"/>
        <span
          class="error-texto-govco alert-entradas-de-texto-govco" role="alert" aria-live="assertive">
          {{ getErrorMessage('book') }}
        </span>
      </div>
    </div>

    <div class="col-sm-12 col-md-6" *ngIf="showInternationalForm == false">
      <div class="entradas-de-texto-govco">
        <label for="Folio">Folio </label>
        <input type="text" id="Folio" placeholder="Folio"
               style="text-transform: uppercase;"
               formControlName="folio" aria-describedby="folio-note"
               [ngClass]="{'error': isTouchedField(requestDataForm, 'folio')}"/>
        <span
          class="error-texto-govco alert-entradas-de-texto-govco" role="alert" aria-live="assertive">
          {{ getErrorMessage('folio') }}
        </span>
      </div>
    </div>

    <div class="col-sm-12 col-md-6" *ngIf="showInternationalForm != null">
      <div class="entradas-de-texto-govco">
        <label for="anio-titulo">Año Título <span class="required"> *</span></label>
        <input type="text" id="anio-titulo" placeholder="Año"
               style="text-transform: uppercase;"
               formControlName="yearTitle" aria-describedby="yearTitle-note"
               [ngClass]="{'error': isTouchedField(requestDataForm, 'yearTitle')}"/>
        <span
          class="error-texto-govco alert-entradas-de-texto-govco" role="alert" aria-live="assertive">
          {{ getErrorMessage('yearTitle') }}
        </span>
      </div>
    </div>

    <div class="col-sm-12 col-md-6" *ngIf="showProfessionalCard==true && showInternationalForm != null">
      <div class="entradas-de-texto-govco">
        <label for="tarjeta-profesional">Tarjeta Profesional <span class="required"> *</span></label>
        <input type="text" id="tarjeta-profesional" placeholder="Tarjeta Profesional"
               style="text-transform: uppercase;"
               formControlName="professionalCard" aria-describedby="professionalCard-note"
               [ngClass]="{'error': isTouchedField(requestDataForm, 'professionalCard')}"/>
        <span
          class="error-texto-govco alert-entradas-de-texto-govco" role="alert" aria-live="assertive">
          {{ getErrorMessage('professionalCard') }}
        </span>
      </div>
    </div>

  </div>

</form>
