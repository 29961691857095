<form role="form" [formGroup]="requestEditInfoForm">
  <div class="row">
    <div class="col-sm-12 col-md-7">
      <div class="entradas-de-texto-govco">
        <label for="procedureId">Número de radicado</label>
        <input type="text" id="procedureId" formControlName="idProcedure">
      </div>
    </div>
    <div class="col-sm-12 col-md-12">
      <label for="observations" class="form-label">Observaciones</label>
      <div class="container-input-texto-govco col-xxl-10 col-md-8 col-lg-8 col-sm-10 col-xs-10  ">

        <textarea type="text" id="observations"  class="form-control" formControlName="observations" rows="3"
                  [maxLength]="400"></textarea>
      </div>
    </div>
  </div>
</form>
