<form role="form" [formGroup]="attachmentform">

<div class="row mt-4">

  <div class="table col-12">
    <div class="table-responsive">
      <table class="table table-striped " aria-describedby="tabla-documentos">
        <thead>
        <tr>
          <th scope="col"></th>
        </tr>
        </thead>
        <tbody>
        <div class="row">
          <div class="col-xs-5 col-sm-5 col-md-5 col-lg-5 col-xl-5 col-xxl-5">
            <div class="colum_first">
              <table class="table" aria-describedby="tabla-visualizacion-documentos">
                <thead>
                <tr>
                  <th scope="col">Descripción</th>
                  <th scope="col">Documento</th>
                  <th scope="col">Cumple?</th>
                </tr>
                </thead>
                <tbody>
                <tr *ngFor="let document of documentSupports">
                  <td>{{document.idDocumentProcNavigation.description}}</td>
                  <td class="d-flex mx-auto">
                    <img (click)="archiveService.viewArchiveInPopUp(document.path)" [src]="urlIconExternalWindow" class="img-fluid icon" alt="imagen-pdf-external">
                    <img (click)="archiveService.viewArchiveActualWindow(document.path)" [src]="urlIconActualWindow" class="img-fluid icon" alt="imagen-pdf-actual">
                  </td>
                  <td>
                    <mat-radio-group name="opciones_{{document.idDocumentTypeProcedureRequest}}" aria-label="Select an option"
                                     (change)="statechange(document.idDocumentTypeProcedureRequest)"  >
                      <mat-radio-button value="1" checked="{{document.is_valid}}"  style="margin: 10px">
                        SI
                      </mat-radio-button>
                      <mat-radio-button  value="2"  checked="{{!document.is_valid}}" style="margin: 10px">
                        NO
                      </mat-radio-button>
                    </mat-radio-group>

                  </td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
          <br>
          <div class="col-xs-7 col-sm-7 col-md-7 col-lg-7 col-xl-7 col-xxl-7">
              <iframe id="prueba"  width="700" height="500" src="" title="visualizacion-documento"></iframe>
          </div>
        </div>
        </tbody>
      </table>
    </div>
  </div>
</div>
</form>
