<div class="row mt-1">
  <div class="col-12">
    <div class="alert alert-success text3-tipografia-govco" role="alert">
      Su solicitud se ha registrado con éxito en el sistema con <strong>número de radicado: {{ numberFiled }}.</strong>
      <br>
      Recuerde que su resolución estará en un plazo máximo de 20 días hábiles a partir del día de hoy.
    </div>
    <div style="margin: 1% 0 1% 0; text-align: center">
      <button type="button" class="btn-govco fill-btn-govco" (click)="exit()">Salir</button>
    </div>
  </div>
</div>

