<form role="form" [formGroup]="naturalForm">
  <div class="row">
    <legend>
      <h3>Registro Persona Natural</h3>
    </legend>

    <div class="alert alert alert-danger" role="alert">
      <p [innerHTML]="cadenaInicioRegistro"></p>
    </div>

    <div class="accordion-govco" id="accordionParent">

      <div class="item-accordion-govco">
        <h2 class="accordion-header" id="accordionIconoPanels-childOne">
          <button class="button-accordion-govco collapsed"
                  type="button"
                  data-bs-toggle="collapse" data-bs-target="#accordionIconoPanels-collapseOne"
                  aria-expanded="false" aria-controls="accordionIconoPanels-collapseOne">
                  <span class="text-button-accordion-govco">
                    <h4 class="h4-tipografia-govco title-1">Datos básicos</h4>
                  </span>
          </button>
        </h2>
        <div id="accordionIconoPanels-collapseOne" class="accordion-collapse collapse"
             aria-labelledby="accordionIconoPanels-childOne" data-bs-parent="#accordionParent">
          <div class="body-accordion-govco">


            <app-basic-data-citizen ></app-basic-data-citizen>


          </div>
        </div>
      </div>


      <div class="item-accordion-govco">
        <h2 class="accordion-header" id="accordionIconoPanels-childTwo">
          <button class="button-accordion-govco collapsed"
                  type="button"
                  data-bs-toggle="collapse" data-bs-target="#accordionIconoPanels-collapseTwo"
                  aria-expanded="false" aria-controls="accordionIconoPanels-collapseTwo">
                  <span class="text-button-accordion-govco">
                    <h4 class="h4-tipografia-govco title-1">Datos Geográficos</h4>
                  </span>
          </button>
        </h2>
        <div id="accordionIconoPanels-collapseTwo" class="accordion-collapse collapse"
             aria-labelledby="accordionIconoPanels-childTwo" data-bs-parent="#accordionParent">
          <div class="body-accordion-govco">


            <app-geographic-data [source]="'register'"   ></app-geographic-data>



            <div class="alert ant-alert-info">
              <strong>Información!</strong>
              Por favor registre su dirección de residencia tal como aparece en el recibo público, en las
              casillas indicadas para esto. Una vez completado los datos, favor dar clic sobre el botón
              verde Confirmar Dirección. Esta funcionalidad permitirá autocompletar datos de UPZ, Localidad
              y Barrio para las direcciones de Bogotá D.C. y estandarizar la dirección para el resto de ciudades.
            </div>

            <div class="row mt-3">
              <div class=" col-md-5 col-lg-3 ">
                <div class="entradas-de-texto-govco">
                  <label for="viaprincipal">Vía Ppal<span class="required">(*)</span></label>
                  <select class="form-select" id="viaprincipal" aria-label="Default select example" formControlName="viaprincipal"
                          (change)="direccion(0,this.naturalForm.get('viaprincipal').value+'')"
                          [ngClass]="{'error': isTouchedField(naturalForm, 'viaprincipal')}">
                    <option value="" selected disabled>Seleccione</option>
                    <option value="AK">AK - Avenida Carrera</option>
                    <option value="AC">AC - Avenida Calle</option>
                    <option value="CL">CL - Calle</option>
                    <option value="DG">DG - Diagonal</option>
                    <option value="KR">KR - Carrera</option>
                    <option value="TV">TV - Transversal</option>
                  </select>
                  <span
                    class="error-texto-govco alert-entradas-de-texto-govco" id="viaprincipal-note" role="alert"
                    aria-live="assertive">
                        {{ getErrorMessage('viaprincipal') }}
                      </span>
                </div>
              </div>

              <div class="group col-md-2 col-lg-2">
                <div class="entradas-de-texto-govco">
                  <label for="Num">Num<span class="required">(*)</span></label>
                  <input type="text" id="Num" placeholder="Num" formControlName="num1"
                         (change)="direccion(1,this.naturalForm.get('num1').value+'')"
                         [ngClass]="{'error': isTouchedField(naturalForm, 'num1')}"/>
                  <span
                    class="error-texto-govco alert-entradas-de-texto-govco" id="num1-note" role="alert"
                    aria-live="assertive">
                        {{ getErrorMessage('num1') }}
                      </span>
                </div>
              </div>

              <div class="col-sm-12 col-md-2">
                <div class="entradas-de-texto-govco">
                  <label for="Letra">Letra</label>
                  <select class="form-select" id="Letra" aria-label="Default select example"
                          formControlName="Letra"
                          (change)="direccion(2,this.naturalForm.get('Letra').value+'')">
                    <option value="" selected disabled>Seleccione</option>
                    <option value="A">A</option>
                    <option value="B">B</option>
                    <option value="C">C</option>
                    <option value="D">D</option>
                    <option value="E">E</option>
                    <option value="F">F</option>
                    <option value="G">G</option>
                  </select>
                </div>
              </div>

              <div class="col-sm-12 col-md-2">
                <div class="entradas-de-texto-govco">
                  <label for="BIS">BIS</label>
                  <select class="form-select" id="BIS" aria-label="Default select example"
                          formControlName="BIS"
                          (change)="direccion(3,this.naturalForm.get('BIS').value+'')">
                    <option value="" selected disabled>Seleccione</option>
                    <option value="BIS">BIS</option>
                  </select>
                </div>
              </div>

              <div class="col-sm-12 col-md-2">
                <div class="entradas-de-texto-govco">
                  <label for="Card">Card</label>
                  <select class="form-select" id="Card" aria-label="Default select example"
                          formControlName="Card"
                          (change)="direccion(4,this.naturalForm.get('Card').value+'')">
                    <option value="" selected disabled>Seleccione</option>
                    <option value="ESTE">ESTE</option>
                    <option value="SUR">SUR</option>
                  </select>
                </div>
              </div>
            </div>


            <div class="row mt-3">

              <div class=" col-md-5 col-lg-3 ">
                <div class="entradas-de-texto-govco">
                  <label for="complemento">Complemento</label>
                  <select class="form-select" id="Complemento" aria-label="Default select example"
                          formControlName="Complemento"
                          (change)="direccion(5,this.naturalForm.get('Complemento').value+'')">
                    <option value="" selected disabled>Seleccione</option>

                  </select>
                </div>
              </div>


              <div class="col-sm-12 col-md-2">
                <div class="entradas-de-texto-govco">
                  <label for="Num">Num<span class="required">(*)</span></label>
                  <input type="text" id="Num2" placeholder="Num" formControlName="num2"
                         [ngClass]="{'error': isTouchedField(naturalForm, 'num2')}"
                         (change)="direccion(6,this.naturalForm.get('num2').value+'')"/>
                  <span
                    class="error-texto-govco alert-entradas-de-texto-govco" id="num2-note" role="alert"
                    aria-live="assertive">
                        {{ getErrorMessage('num2') }}
                      </span>
                </div>
              </div>

              <div class="col-sm-12 col-md-2">
                <div class="entradas-de-texto-govco">
                  <label for="Letra">Letra</label>
                  <select class="form-select" id="Letra2" aria-label="Default select example"
                          formControlName="Letra2"
                          (change)="direccion(7,this.naturalForm.get('Letra2').value+'')">
                    <option value="" selected disabled>Seleccione</option>
                    <option value="A">A</option>
                    <option value="B">B</option>
                    <option value="C">C</option>
                    <option value="D">D</option>
                    <option value="E">E</option>
                    <option value="F">F</option>
                    <option value="G">G</option>
                  </select>
                </div>
              </div>


              <div class="col-sm-12 col-md-2">
                <div class="entradas-de-texto-govco">
                  <label for="placa">Placa<span class="required">(*)</span></label>
                  <input type="text" id="placa" placeholder="placa" formControlName="placa"
                         [ngClass]="{'error': isTouchedField(naturalForm, 'placa')}"
                         (change)="direccion(8,this.naturalForm.get('placa').value+'')"/>
                  <span
                    class="error-texto-govco alert-entradas-de-texto-govco" id="placa-note" role="alert"
                    aria-live="assertive">
                        {{ getErrorMessage('placa') }}
                      </span>
                </div>
              </div>

              <div class="col-sm-12 col-md-2">
                <div class="entradas-de-texto-govco">
                  <label for="Card">Card</label>
                  <select class="form-select" id="Card2" aria-label="Default select example"
                          formControlName="Card2"
                          (change)="direccion(9,this.naturalForm.get('Card2').value+'')">
                    <option value="" selected disabled>Seleccione</option>
                    <option value="ESTE">ESTE</option>
                    <option value="SUR">SUR</option>
                  </select>
                </div>
              </div>
            </div>

            <div class="row mt-3 ">
              <div class=" col-md-5 col-lg-3 ">
                <div class="entradas-de-texto-govco">
                  <label for="adicional">Adicional</label>
                  <select class="form-select" id="Adicional" aria-label="Default select example"
                          (change)="direccion(10,this.naturalForm.get('Adicional').value+'')">
                    <option value="" selected disabled>Seleccione</option>

                  </select>
                </div>
              </div>
              <div class="col-sm-12 col-md-2">
                <div class="entradas-de-texto-govco">
                  <label for="Detalle">Detalle</label>
                  <input type="text" id="Detalle" placeholder="Num" formControlName="Detalle"
                         (change)="direccion(11,this.naturalForm.get('Detalle').value+'')"/>
                </div>
              </div>
            </div>

            <div class="row mt-3 ">
              <div class="col-sm-12 col-md-9">
                <div class="entradas-de-texto-govco">
                  <label for="Dirección">Dirección<span class="required">(*)</span></label>
                  <input type="text" id="Dirección" formControlName="direccionResidencia"
                         [ngClass]="{'error': isTouchedField(naturalForm, 'direccionResidencia')}" [disabled]="true"/>
                  <span
                    class="error-texto-govco alert-entradas-de-texto-govco" id="direccionResidencia-note" role="alert"
                    aria-live="assertive">
                        {{ getErrorMessage('direccionResidencia') }}
                      </span>
                </div>
              </div>
              <div class="col-12">
                <button type="button" class="btn-govco fill-btn-govco">Confirmar Dirección</button>
              </div>
            </div>



            <div class="row mt-3 ">
              <div class=" col-md-5 col-lg-3 ">
                <div class="entradas-de-texto-govco">
                  <label for="zona">Zona</label>
                  <select class="form-select" id="Zona" aria-label="Default select example" formControlName="zona"
                          [ngClass]="{'error': isTouchedField(naturalForm, 'zona')}">
                    <option value="" selected disabled>Seleccione</option>
                    <option *ngFor="let zona of zoneslist" [value]="zona.idSubRed">{{zona.nombre}}</option>

                  </select>
                  <span
                    class="error-texto-govco alert-entradas-de-texto-govco" id="zona-note" role="alert"
                    aria-live="assertive">
                        {{ getErrorMessage('zona') }}
                      </span>
                </div>
              </div>
              <div class=" col-md-5 col-lg-3 ">
                <div class="entradas-de-texto-govco">
                  <label for="localidad">Localidad</label>
                  <select class="form-select" id="Localidad" aria-label="Default select example" formControlName="localidad"
                          [ngClass]="{'error': isTouchedField(naturalForm, 'localidad')}">
                    <option value="" selected disabled>Seleccione</option>
                    <option *ngFor="let localidad of localitieslist" [value]="localidad.idLocalidad">{{localidad.nombre}}</option>
                  </select>
                  <span
                    class="error-texto-govco alert-entradas-de-texto-govco" id="localidad-note" role="alert"
                    aria-live="assertive">
                        {{ getErrorMessage('localidad') }}
                      </span>
                </div>
              </div>
            </div>
            <div class="row mt-3 ">
              <div class=" col-md-5 col-lg-3 ">
                <div class="entradas-de-texto-govco">
                  <label for="upz">Upz</label>
                  <select class="form-select" id="Upz" aria-label="Default select example" formControlName="upz"
                          [ngClass]="{'error': isTouchedField(naturalForm, 'upz')}">
                    <option value="" selected disabled>Seleccione</option>
                    <option *ngFor="let upz of upzlist" [value]="upz.id_upz">{{upz.nom_upz}}</option>

                  </select>
                  <span
                    class="error-texto-govco alert-entradas-de-texto-govco" id="upz-note" role="alert"
                    aria-live="assertive">
                        {{ getErrorMessage('upz') }}
                      </span>
                </div>
              </div>
              <div class=" col-md-5 col-lg-3 ">
                <div class="entradas-de-texto-govco">
                  <label for="barrio">Barrio</label>
                  <select class="form-select" id="Barrio" aria-label="Default select example" formControlName="barrio"
                          [ngClass]="{'error': isTouchedField(naturalForm, 'barrio')}">
                    <option value="" selected disabled>Seleccione</option>
                    <option *ngFor="let barrio of barriolist" [value]="barrio.id_barrio">{{barrio.nombre_barrio}}</option>
                  </select>
                  <span
                    class="error-texto-govco alert-entradas-de-texto-govco" id="barrio-note" role="alert"
                    aria-live="assertive">
                        {{ getErrorMessage('barrio') }}
                      </span>
                </div>
              </div>
            </div>


          </div>
        </div>
      </div>


      <div class="item-accordion-govco">
        <h2 class="accordion-header" id="accordionIconoPanels-chilTree">
          <button class="button-accordion-govco collapsed"
                  type="button"
                  data-bs-toggle="collapse" data-bs-target="#accordionIconoPanels-collapseTree"
                  aria-expanded="false" aria-controls="accordionIconoPanels-collapseTree">
                  <span class="text-button-accordion-govco">
                    <h4 class="h4-tipografia-govco title-1">Datos Demográficos</h4>
                  </span>
          </button>
        </h2>
        <div id="accordionIconoPanels-collapseTree" class="accordion-collapse collapse"
             aria-labelledby="accordionIconoPanels-childOne" data-bs-parent="#accordionParent">
          <div class="body-accordion-govco">

            <div class="col-sm-12 col-md-3">
              <div class="entradas-de-texto-govco">
                <label for="fecha-de-nacimiento">Fecha de nacimiento<span class="required">(*)</span></label>
                <input type="date" id="fecha-de-nacimiento"
                       placeholder="Fecha de nacimiento" formControlName="fechaNacimiento"
                       (click)="blockCalendarFutureDate('fecha-de-nacimiento')"
                       [ngClass]="{'error': isTouchedField(naturalForm, 'fechaNacimiento')}"/>
                <span
                  class="error-texto-govco alert-entradas-de-texto-govco" id="fechaNacimiento-note" role="alert"
                  aria-live="assertive">
            {{ getErrorMessage('fechaNacimiento') }}
        </span>
              </div>
            </div>

            <div class="col-sm-12 col-md-3">
              <div class="entradas-de-texto-govco">
                <label for="Sexo">Sexo<span class="required">(*)</span></label>
                <select class="form-select" id="Sexo" aria-label="Default select example" formControlName="sexo"
                        [ngClass]="{'error': isTouchedField(naturalForm, 'sexo')}">
                  <option value="" disabled selected>Seleccione</option>
                  <option *ngFor="let sexo of sexes" [value]="sexo.idSexo">{{sexo.descripcionSexo}}</option>
                </select>
                <span
                  class="error-texto-govco alert-entradas-de-texto-govco" id="sexo-note" role="alert"
                  aria-live="assertive">
            {{ getErrorMessage('sexo') }}
        </span>
              </div>
            </div>

            <div class="col-sm-12 col-md-3">
              <div class="entradas-de-texto-govco">
                <label for="Género">Género<span class="required">(*)</span></label>
                <select class="form-select" id="Género" aria-label="Default select example" formControlName="genero"
                        [ngClass]="{'error': isTouchedField(naturalForm, 'genero')}">
                  <option value="" disabled selected>Seleccione</option>
                  <option *ngFor="let genero of gender" [value]="genero.orden">{{genero.descripcion}}</option>
                </select>
                <span
                  class="error-texto-govco alert-entradas-de-texto-govco" id="genero-note" role="alert"
                  aria-live="assertive">
            {{ getErrorMessage('genero') }}
        </span>
              </div>
            </div>

            <div class="col-sm-12 col-md-3">
              <div class="entradas-de-texto-govco">
                <label for="Orientación sexual">Orientación sexual<span class="required">(*)</span></label>
                <select class="form-select" id="Orientación sexual" aria-label="Default select example"
                        formControlName="orientacionSexual"
                        [ngClass]="{'error': isTouchedField(naturalForm, 'orientacionSexual')}">
                  <option value="" disabled selected>Seleccione</option>
                  <option *ngFor="let orientacion of sexualOrientation"
                          [value]="orientacion.orden">{{orientacion.descripcion}}</option>
                </select>
                <span
                  class="error-texto-govco alert-entradas-de-texto-govco" id="orientacionSexual-note" role="alert"
                  aria-live="assertive">
            {{ getErrorMessage('orientacionSexual') }}
        </span>
              </div>
            </div>

            <div class="col-sm-12 col-md-4">
              <div class="entradas-de-texto-govco">
                <label for="Etnia">Etnia<span class="required">(*)</span></label>
                <select class="form-select" id="Etnia" aria-label="Default select example" formControlName="etnia"
                        [ngClass]="{'error': isTouchedField(naturalForm, 'etnia')}">
                  <option value="" disabled selected>Seleccione</option>
                  <option *ngFor="let etnia of ethnicity" [value]="etnia.idEtnia">{{etnia.nombre}}</option>
                </select>
                <span
                  class="error-texto-govco alert-entradas-de-texto-govco" id="etnia-note" role="alert"
                  aria-live="assertive">
            {{ getErrorMessage('etnia') }}
        </span>
              </div>
            </div>

            <div class="col-sm-12 col-md-4">
              <div class="entradas-de-texto-govco">
                <label for="Estado Civil">Estado Civil<span class="required">(*)</span></label>
                <select class="form-select" id="Estado Civil" aria-label="Default select example"
                        formControlName="estadoCivil" [ngClass]="{'error': isTouchedField(naturalForm, 'estadoCivil')}">
                  <option value="" selected disabled>Seleccione</option>
                  <option *ngFor="let maritalStat of maritalStatus"
                          [value]="maritalStat.key">{{maritalStat.name}}</option>


                  <option value="1">SOLTERO(A)</option>
                  <option value="2">CASADO(A)</option>
                  <option value="3">UNIÓN LIBRE</option>
                  <option value="4">VIUDO(A)</option>
                  <option value="5">DIVORCIADO(A)/SEPARADO(A)</option>
                </select>
                <span
                  class="error-texto-govco alert-entradas-de-texto-govco" id="estadoCivil-note" role="alert"
                  aria-live="assertive">
            {{ getErrorMessage('estadoCivil') }}
        </span>
              </div>
            </div>

            <div class="col-sm-12 col-md-4">
              <div class="entradas-de-texto-govco">
                <label for="Nivel Educativo">Nivel Educativo<span class="required">(*)</span></label>
                <select class="form-select" id="Nivel Educativo" aria-label="Default select example"
                        formControlName="nivelEducativo"
                        [ngClass]="{'error': isTouchedField(naturalForm, 'nivelEducativo')}">
                  <option value="" disabled selected>Seleccione</option>
                  <option *ngFor="let niveleducativo of educationLevel"
                          [value]="niveleducativo.idNivelEducativo">{{niveleducativo.nombre}}</option>
                </select>
                <span
                  class="error-texto-govco alert-entradas-de-texto-govco" id="nivelEducativo-note" role="alert"
                  aria-live="assertive">
            {{ getErrorMessage('nivelEducativo') }}
        </span>
              </div>
            </div>

            <div id="div_enviar" class="col-12 col-md-12 pl-4 mt-4">
              <div class="col-sm-12">
                <input type="checkbox" name="acepta_terminos" id="acepta_terminos" formControlName="checkBoxDatosPersonales"
                       [ngClass]="{'error': isTouchedField(naturalForm, 'checkBoxDatosPersonales')}">
                He leído y acepto la politica de protección y tratamiento de datos personales
                <span class="required">(*)</span>

              </div>
              <span
                class="error-texto-govco alert-entradas-de-texto-govco" id="checkBoxDatosPersonales-note" role="alert"
                aria-live="assertive">
            {{ getErrorMessage('checkBoxDatosPersonales') }}
        </span>
            </div>


          </div>
        </div>
      </div>



    </div>
  </div>
  <div class="row mt-3 ">
    <div class="col-sm-12 col-md-9 col-lg-3">
      <button  (click)="cancelar()" type="button" style="margin-left: 7rem;" class="btn-govco fill-btn-govco">Cancelar</button>
    </div>
  <div class="col-sm-12 col-md-9 col-lg-3">
    <button  (click)="guardar()" type="button" style="margin-left: 1rem;" class="btn-govco fill-btn-govco">Guardar</button>
  </div>
  </div>

</form>
