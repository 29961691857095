<div class="d-flex flex-column general-layout">
  <div>
    <app-header></app-header>
    <app-breadcrumb></app-breadcrumb>
  </div>
  <div class="content-layout" id="content-layout-id">

    <main class="scroll" id="interno">
      <div class="information">
        <router-outlet *ngIf="!isIframe"></router-outlet>
      </div>
      <aside>
        <app-accesibility></app-accesibility>
      </aside>

      <app-go-up></app-go-up>
      <div class="row align-content-center mx-auto" *ngIf="ocultarRegistroValidacion==true">
        <div class="row mt-3 ">
          <div class="col-sm-12 col-md-9 col-lg-3">
            <button  (click)="registro()" type="button" style="margin-left: 7rem;" class="btn-govco fill-btn-govco">Registro</button>
          </div>
          <div class="col-sm-12 col-md-9 col-lg-3">
            <button  (click)="validacion()" type="button" style="margin-left: 1rem;" class="btn-govco fill-btn-govco">Validación de Documentos</button>
          </div>
        </div>
      </div>
      <div class="linea">
        <app-footer></app-footer>
      </div>
    </main>
  </div>
</div>

