<div class="container-fluid">
  <div class="row">
    <div class="col-sm-12 col-md-9">
      <app-advance-line [step]="stepAdvanceLine" [currentProgress]="currentProgressAdvanceLine"
                        [source]="'ciudadano'"></app-advance-line>

      <form [formGroup]="userdashboard" *ngIf="showDashboard==true && showDisclaimerForm==false">
        <br><h4 class="h4-tipografia-govco title-1">Bandeja de Entrada y Gestión</h4><br>

        <div class="row  justify-content-center align-items-center mx-auto " style="width: 100%">
          <ul class="nav nav-tabs" id="myTab" role="tablist">
            <li class="nav-item" role="presentation">
              <button (click)="filterTable(0,0)" class="nav-link active" id="home-tab" data-bs-toggle="tab"
                      data-bs-target="#home-tab-pane" type="button" role="tab" aria-controls="home-tab-pane"
                      aria-selected="true">Recientes
              </button>
            </li>
            <li class="nav-item">
              <button (click)="filterTable(1,0)" class="nav-link" id="profile-tab" data-bs-toggle="tab"
                      data-bs-target="#profile-tab-pane" type="button" role="tab" aria-controls="profile-tab-pane"
                      aria-selected="false">Solucionados
              </button>
            </li>
          </ul>
          <div class="border tab-content" id="myTabContent">

            <br>
            <p>Filtrar por:</p>
            <div class="row  entradas-de-texto-govco">

              <div class="mt-3 col-xxl-4 col-md-4 col-lg-4 col-sm-4 col-xs-4">
                <select class="form-select" id="selector" aria-label="Default select example"
                        formControlName="selector">
                  <option value="" selected disabled>Seleccione</option>
                  <option value="filed_number">Id Solicitud</option>
                  <option value="TitleType">Origen titulo</option>
                  <option value="filed_date">Fecha de Registro Solicitud</option>
                  <option value="status">Estado de la Solicitud</option>
                  <option value="tiempo">Tiempo de Atención Restante</option>
                </select>
              </div>
              <div class="mt-3 col-xxl-5 col-md-5 col-lg-5 col-sm-5 col-xs-5">
                <div>
                  <input type="text" id="textfilter" formControlName="textfilter" placeholder="Buscar por palabra"/>
                </div>
              </div>
              <div class="mt-3 col-xxl-3 col-md-3 col-lg-3 col-sm-3 col-xs-3">
                <div>
                  <button type="button" style="margin-left: 1rem;" (click)="filterTable(this.filter,0)"
                          class="btn-govco fill-btn-govco">
                    Buscar
                  </button>
                </div>
              </div>

            </div>
            <br>
            <div>
              <div class="table col-12">
                <div class="table-responsive">
                  <table class="table table-striped" aria-describedby="dashboard-usuario">
                    <thead>
                    <tr>
                      <th [scope]="">No. de radicado</th>
                      <th [scope]="">Origen titulo</th>
                      <th [scope]="">Fecha de radicación</th>
                      <th [scope]="">Institución</th>
                      <th [scope]="">Programa</th>
                      <th [scope]="">Estado</th>
                      <th [scope]="">Acciones</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr *ngFor="let item of filterAllByUser; let i= index">
                      <td>{{item.filed_number}}</td>
                      <td>{{item.titleType}}</td>
                      <td>{{item.filedDate | date: 'dd/MM/yyyy'}}</td>
                      <td>{{item.institute}}</td>
                      <td>{{item.profession}}</td>
                      <td style="background-color: #70AF3D;">{{this.changestatus(item.status)}}</td>
                      <td class="text-justify">
                        <div class="">
                      <span (click)="loadTrackingProcedure(item.idProcedureRequest)" data-bs-toggle="modal"
                            data-bs-target="#seguimiento-auditoria" class="govco-eye"
                            style="font-size: 20px; color: #3366cc; cursor: pointer;"></span>
                          <br/>
                          Seguimiento Auditoria

                          <!-- Modal -->
                          <div class="modal fade" data-bs-backdrop="static" data-bs-keyboard="false"
                               id="seguimiento-auditoria" tabindex="-1" aria-labelledby="exampleModalLabel"
                               aria-hidden="true">
                            <div class="modal-dialog modal-dialog-centered modal-xl">
                              <div class="modal-content">
                                <div class="modal-header" style="background: #3366CC">
                                  <h5 class="modal-title" style="color:#E6EFFD;" id="exampleModalLabel">Ventana de
                                    Seguimiento y Observaciones</h5>
                                  <button type="button" class="btn-close btn-close-white" data-bs-dismiss="modal"
                                          aria-label="Close"></button>
                                </div>
                                <div class="modal-body">
                                  <h5 class="ant-modal-title">Tabla de seguimiento</h5>
                                  <br>
                                  <table class="table" aria-describedby="modal-seguimiento">
                                    <thead>
                                    <tr>
                                      <th [scope]="">Fecha Seguimiento</th>
                                      <th [scope]="">Observación</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <tr *ngFor="let tracking of trackingRequest">
                                      <td>{{tracking.date_tracking | date: 'dd/MM/yyyy '}}</td>
                                      <td>{{cleanStringObservations(tracking.observations) !="" ?
                                        (cleanStringObservations(tracking.observations) !=" "?
                                        cleanStringObservations(tracking.observations) :
                                        tracking.status) :
                                        tracking.status}}</td>
                                    </tr>
                                    </tbody>
                                  </table>
                                </div>
                                <div class="modal-footer">
                                  <button type="button" class="btn-govco fill-btn-govco" data-bs-dismiss="modal">
                                    Cerrar
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div class="">
                          <a *ngIf="item.statusId != 7 && item.statusId != 8 && item.statusId<11 ">
                            <span class="govco-book-reader" style="font-size: 15px">En validación</span></a>
                          <a (click)="showEditProcedure(item.idProcedureRequest, item.filed_number, item.titleType)" *ngIf="item.statusId == 7">
                            <span class="govco-edit" style="font-size: 15px">Editar Trámite</span>
                          </a>
                          <a (click)="archiveService.viewArchiveInPopUp(item.resolutionPath)"
                             *ngIf="item.statusId == 16 || item.statusId == 17 || item.statusId == 18 || item.statusId == 20">
                            <span class="govco-file-pdf" style="font-size: 15px">Ver documento</span>
                          </a>
                          <a (click)="showClarification(item.idProcedureRequest, item.filed_number, item.titleType)"
                             *ngIf="item.statusId == 16">
                            <span class="govco-hand-paper" style="font-size: 15px">Solicitar Aclaración o Reposición</span>
                          </a>
                        </div>

                      </td>
                    </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <mat-paginator  *ngIf="this.showpaginator==true" length="{{totalRequests}}"
                             [pageSizeOptions]="[5, 10, 25, 50, 100]"
                             (page)="changePage($event)"
                             aria-label="Select page"
                             showFirstLastButtons="true"
                             pageSize="{{pageSizePaginator}}">
              </mat-paginator>
            </div>

          </div>

        </div>
      </form>

      <form [formGroup]="editRequestForm" *ngIf="showEditProcedureForm==true && showDashboard==false">
        <app-edit-request [editRequest]="editRequest"></app-edit-request>
        <div class="col-12" style="margin: 5% 0 5% 0; text-align: center">
          <button type="button" class="btn-govco fill-btn-govco"
                  (click)="showDashboard=true;showEditProcedureForm=false"
                  style="margin-right: 1%;" [disabled]="sending">Volver
          </button>
          <button (click)="ValidarNombre()" type="button" class="btn-govco fill-btn-govco" [disabled]="sending">
            Guardar
          </button>
        </div>
      </form>

      <form [formGroup]="requestClarificationForm" *ngIf="showDisclaimerForm==true && showDashboard==false">
        <app-request-clarification [infoRequest]="infoRequest"></app-request-clarification>
        <div class="col-12" style="margin: 5% 0 5% 0; text-align: center">
          <button type="button" class="btn-govco fill-btn-govco"
                  (click)="showDashboard=true;showDisclaimerForm=false"
                  style="margin-right: 1%;" [disabled]="sending">Volver
          </button>
          <button (click)="saveClarification()" type="button" class="btn-govco fill-btn-govco" [disabled]="sending">
            Guardar
          </button>
        </div>
      </form>


    </div>

    <div class="col-sm-12 col-md-5 col-lg-3">
      <app-service-area></app-service-area>
    </div>
    <div class="loading loading--show">
      <div class="spin"></div>
    </div>

  </div>
</div>



