/**
 * Enumeraciones con los tipos de roles
 */
export enum Rol {
  Citizen = 'Ciudadano',
  Validator = 'Funcionario',
  Coordinator = 'Coordinador',
  Director = 'Subdirector',
  Admin = 'AdminTI',
AdminFu = 'AdminFuncional'

}
