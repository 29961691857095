<div class="container-example-linea-avance-govco" style="margin: initial!important;" *ngIf="source=='ciudadano'">
  <div class="linea-avance-govco h-linea-avance-govco" id="lineaAvance1">
    <div class="progress">
      <div class="progress-bar" role="progressbar" [ngStyle]="{width: currentProgress+'%'}" aria-valuenow="50"
           aria-valuemin="0" aria-valuemax="100"></div>
    </div>
    <div class="items-header-linea-avance-govco">
      <div class="col-3 header-linea-avance-govco" [ngClass]="{'active-linea-avance-govco': step === 1}">
        <div class="indicator-linea-avance-govco" data-la-target="#itemLineaAvance11">1</div>
        <span class="title-linea-avance-govco">Inicio</span>
      </div>
      <div class="col-3 header-linea-avance-govco choice-option" [ngClass]="{'active-linea-avance-govco': step === 2}"
      >
        <div (click)="redirect('ciudadano/crear-solicitud')" class="indicator-linea-avance-govco" data-la-target="#itemLineaAvance12">2</div>
        <span (click)="redirect('ciudadano/crear-solicitud')" class="title-linea-avance-govco">Hago mi solicitud</span>
      </div>
      <div class="col-3 header-linea-avance-govco" [ngClass]="{'active-linea-avance-govco': step === 3}">
        <div class="indicator-linea-avance-govco" data-la-target="#itemLineaAvance13">3</div>
        <span class="title-linea-avance-govco">Procesan mi solicitud</span>
      </div>
      <div class="col-3 header-linea-avance-govco choice-option" [ngClass]="{'active-linea-avance-govco': step === 4}"
      >
        <div (click)="redirect('ciudadano/listado-solicitudes')" class="indicator-linea-avance-govco" data-la-target="#itemLineaAvance14">4</div>
        <span (click)="redirect('ciudadano/listado-solicitudes')" class="title-linea-avance-govco">Respuesta</span>
      </div>
    </div>
  </div>
</div>

<div class="container-example-linea-avance-govco" style="margin: initial!important;" *ngIf="source=='validador'">
  <div class="linea-avance-govco h-linea-avance-govco" id="lineaAvance2">
    <div class="progress">
      <div class="progress-bar" role="progressbar" [ngStyle]="{width: currentProgress+'%'}" aria-valuenow="50"
           aria-valuemin="0" aria-valuemax="100"></div>
    </div>
    <div class="items-header-linea-avance-govco">
      <div class="col-3 header-linea-avance-govco" [ngClass]="{'active-linea-avance-govco': step === 1}">
        <div class="indicator-linea-avance-govco" data-la-target="#itemLineaAvance21">1</div>
        <span class="title-linea-avance-govco">Inicio</span>
      </div>
      <div class="col-3 header-linea-avance-govco choice-option" [ngClass]="{'active-linea-avance-govco': step === 2}"
      >
        <div (click)="redirect('validador/bandeja')"class="indicator-linea-avance-govco" data-la-target="#itemLineaAvance22">2</div>
        <span (click)="redirect('validador/bandeja')" class="title-linea-avance-govco">Validar Trámite</span>
      </div>
      <div class="col-3 header-linea-avance-govco choice-option" [ngClass]="{'active-linea-avance-govco': step === 3}">
        <div (click)="redirect('reportes/dashboard')"class="indicator-linea-avance-govco" data-la-target="#itemLineaAvance23">3</div>
        <span (click)="redirect('reportes/dashboard')" class="title-linea-avance-govco">Menú Reportes</span>
      </div>
      <div class="col-3 header-linea-avance-govco choice-option" [ngClass]="{'active-linea-avance-govco': step === 4}"
      >
        <div class="indicator-linea-avance-govco" data-la-target="#itemLineaAvance24">4</div>
        <span class="title-linea-avance-govco">Cerrar Sesión</span>
      </div>
    </div>
  </div>
</div>
