<form [formGroup]="reportsform" class="mt-3">

  <app-advance-line [step]="stepAdvanceLine" [currentProgress]="currentProgressAdvanceLine"
                    [source]="'validador'"></app-advance-line>
  <br>

  <h3><strong> Trámite: Registro y autorización de titulos en el area de la salud.</strong></h3>
  <br>
  <div class="col-xxl-6 col-md-6 col-lg-6 col-sm-6 col-xs-6">
    <h2>Filtro Consulta y Descarga Excel Trámites Aprobados</h2>
  </div>
  <div class="col-xxl-6 col-md-6 col-lg-6 col-sm-6 col-xs-6">
    <h3>Autorización de Títulos en Área de la Salud</h3>
  </div>

  <div class="row mt-3">
    <div class="col-sm-10 col-md-3 col-lg-4">
      <div class="entradas-de-texto-govco">
        <label for="begindate">Fecha Inicial</label>
        <input type="date" id="begindate" max="{{this.actualday}}"
               placeholder="Fecha Seguimiento Inicial" formControlName="begindate"
        />
      </div>
    </div>

    <div class="col-sm-10 col-md-3 col-lg-4">
      <div class="entradas-de-texto-govco">
        <label for="enddate">Fecha Final</label>
        <input type="date" id="enddate" max="{{this.actualday}}"
               placeholder="Fecha Seguimiento Final" formControlName="enddate"
        />
      </div>
    </div>

    <div class="col-sm-10 col-md-5 col-lg-4">
      <div class="entradas-de-texto-govco">
        <label>Tipo reporte</label>
        <select class="form-select" id="selector" aria-label="Default select example"
                formControlName="selector"
        >
          <option value="" selected disabled>Seleccione</option>
          <option value="">Consultar trámites por N° de Identificación</option>
          <option value="aprobado">Consultar trámites Aprobados</option>
          <option value="Tramite Aclarado">Consultar trámites Aclarados</option>
          <option value="Tramite Negado">Consultar trámites Rechazados</option>
          <option value="aclaración">Consultar trámites en Recurso Reposición o Aclaración</option>
          <option value="negado">Consultar trámites Negados</option>
          <option value="anular">Consultar trámites Anulados</option>
          <option value="Registro de Solicitud">Consultar trámites Solicitados</option>
          <option value="3030aprobado">Resolucion 3030 de 2014 Aprobados</option>
          <option value="3030aclarado">Resolucion 3030 de 2014 Aclarados</option>
        </select>
      </div>
    </div>

  </div>


  <div class="col-sm-8 col-md-8 col-lg-8">
    <button (click)="descargarArchivo()" type="button" class="btn-govco fill-btn-govco"> Descargar Excel (CSV)</button>
  </div>


  <br><br>

  <div class="col-12">
    <div class="alert alert-primary text3-tipografia-govco" role="alert">
      <h1>¡Apreciado Usuario!</h1>
      <p>La consulta y el reporte de trámites en estado de Aprobación, es por rango de fecha de acuerdo
        de la resolución generada por el sistema.
      </p>
      <p>Los trámites visualizados corresponde a los últimos 30 dias desde la fecha actual.</p>
    </div>
  </div>

  <br>
  <div class="row mt-3 entradas-de-texto-govco">
    <div class="col-xxl-2 col-md-2 col-lg-2 col-sm-3 col-xs-2">
      <p>Buscar:</p>
    </div>
    <div class="col-xxl-4 col-md-4 col-lg-4 col-sm-4 col-xs-4">
      <input type="text" id="textfilter" formControlName="textfilter"/>
    </div>
    <div class="col-xxl-4 col-md-4 col-lg-4 col-sm-4 col-xs-4">
      <button (click)="getDashboard()" type="button" class="btn-govco fill-btn-govco">Filtrar</button>
    </div>
  </div>
  <br>

  <div class="row">


    <div class="row mx-auto ">
      <div class="table ">
        <div class="table-responsive">
          <table class="table table-striped " aria-describedby="tabla-reportes">
            <tr>
              <th [scope]="">ID Solicitud</th>
              <th [scope]="">No. Doc de Identidad</th>
              <th [scope]=""> Tipo de Documento</th>
              <th [scope]="">Nombres y Apellidos</th>
              <th [scope]="">Tipo de Título</th>
              <th [scope]="">Fecha de Registro Solicitud</th>
              <th [scope]="">Acciones Permitidas</th>
              <th [scope]="">Estado de la Solicitud</th>
              <th [scope]="">PDF</th>
            </tr>
            <tbody>
            <tr *ngFor="let item of tableFilter; let i= index">
              <td>{{item.idfiled}}</td>
              <td> {{item.idnumber}}  </td>
              <td>{{item.iddoctype}}</td>
              <td>{{item.aplicantname}}</td>
              <td>{{item.titletype}}</td>
              <td>{{item.fileddate| date: 'dd-MM-yyyy'}}</td>
              <td class="text-justify">
                <a (click)="validar(item.idprocedure)"> <u class="govco-edit-1">Validar</u> </a>
              </td>
              <td *ngIf="item.statusstring != null">{{item.statusstring}}</td>
              <td *ngIf="item.resolutionpath == ''"></td>
              <td *ngIf="item.resolutionpath != ''">
                <div class="row">
                  <div class="col-lg-12 col-xl-12  col-md-12 col-sm-12 col-xs-12">
                    <img alt="icono-pdf-external" (click)="archiveService.viewArchiveInPopUp(item.resolutionpath)"
                         [src]="urlIconExternalWindow" class="img-fluid icon">
                  </div>
                  <div class="col-lg-12 col-xl-12 col-md-12 col-sm-12 col-xs-12">
                    <img alt="icono-pdf-external" (click)="archiveService.viewArchiveExternal(item.resolutionpath)"
                         [src]="urlIconExternalWindow" class="img-fluid icon">
                  </div>
                </div>
              </td>

            </tr>
            </tbody>
          </table>
        </div>
      </div>

      <mat-paginator length="{{totalRequests}}"
                     [pageSizeOptions]="[5, 10, 25, 50, 100]"
                     (page)="changePage($event)"
                     aria-label="Select page"
                     showFirstLastButtons="true"
                     pageSize="{{pageSizePaginator}}">
      </mat-paginator>


    </div>

  </div>


</form>


