import {Component, OnInit, ViewChild} from '@angular/core';
import {Router} from "@angular/router";
import {FormBuilder, FormGroup} from "@angular/forms";
import {MatPaginator, PageEvent} from "@angular/material/paginator";

import {ROUTES} from "@core-app/enums";
import {PopUpService, RegisterService, RequestService, TrackingService} from "@core-app/services";
import {formatDate} from "@angular/common";
import {lastValueFrom} from "rxjs";
import Swal from 'sweetalert2';

/**
 * Componente para la bandeja del funcionario con el listado de solicitudes pendientes por validar
 */
@Component({
  selector: 'app-validators-dashboard',
  templateUrl: './validators-dashboard.component.html',
  styleUrls: ['./validators-dashboard.component.scss']
})
export class ValidatorsDashboardComponent implements OnInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  /**
   * Formulario para los filtros de busqueda
   */
  public validatorForm: FormGroup;

  /**
   * Lista con las solicitudes a mostrar en el dashboard
   */
  public tableFilter: any[] = [];

  /**
   * Atributo para paginador, items por página
   */
  public pageSizePaginator: number = 10;

  /**
   * Atributo para paginador, número de pagina actual
   */
  public pageNumberPaginator: number = 1;

  /**
   * Número total de solicitudes
   */
  public totalRequests: number = 0;
  /**
   * Mensaje de :Mostrando n - n
   */
  public message: string = '';

  /**
   * Filtros de busqueda
   */
  public lastfilters: any = {
    finaldate: "",
    texttosearch: " ",
    selectedfilter: " ",
    pagenumber: this.pageNumberPaginator,
    pagination: this.pageSizePaginator
  }

  /**
   * Modela el numero a pintar en la linea de avance
   */
  public stepAdvanceLine: number;

  /**
   * Modela la barra de progreso a pintar en la linea de avance
   */
  public currentProgressAdvanceLine: number;
  /**
   * barra de busqueda para los filtros
   */
  public seachbar: boolean=true;
  /**
   * fecha para los filtros
   */
  public datebar: boolean=false;
  /**
   * dia actual
   */
  public actualday:string="";


  constructor(public requestService: RequestService,
              public trackingService: TrackingService,
              public fb: FormBuilder,
              private popupAlert: PopUpService,
              private router: Router,
              public registerService: RegisterService,
  ) {
    this.currentProgressAdvanceLine = 50;
    this.stepAdvanceLine = 2;
    this.validatorForm = this.fb.group({
      selector: [''],
      selectorrole: [localStorage.getItem('Role')],
      textfilter: [''],
      fecha:[],
      pageSize: [this.pageSizePaginator],
      pageNumber: [this.pageNumberPaginator],
    });
  }

  ngOnInit(): void {

    localStorage.removeItem('source');
    let role: string = localStorage.getItem('Role');

    let date: Date = new Date(Date.now());

    // Get year, month, and day part from the date
    let year = date.toLocaleString("default", {year: "numeric"});
    let month = date.toLocaleString("default", {month: "2-digit"});
    let day = date.toLocaleString("default", {day: "2-digit"});

    // Generate yyyy-mm-dd date string
    let formattedDate = year + "-" + month + "-" + day;
    this.actualday=formattedDate;
    this.requestService.getDashboardValidation(
      formattedDate + "",
      "" + " ",
      "" + " ",
      `${this.pageNumberPaginator}`,
      `${this.pageSizePaginator}`,
      role)
      .subscribe(async resp => {
        let tabla =  resp.data;
        let listasolicitudes: string[] =[]


        for (const element of tabla)
        {
          listasolicitudes.push(element.fileddate.substring(0,10)+"");
        }


        let respuesta = await lastValueFrom(this.trackingService.getRemainingdayslista(
          {
            initialDates: listasolicitudes,
            idTramite: "7073DE5F-C45F-4CD9-937B-FAA155C6FD22"
          }
        ));
        for (let i = 0; i <tabla.length ; i++) {
          let diasrestantes = respuesta.data[i].personaNatural.diasRestantes;
          let diastranscurridos = respuesta.data[i].personaNatural.diasHabilesTanscurridos;
          let diastotales = respuesta.data[i].personaNatural.totalANS;
          let texto = "Quedan " + (diasrestantes >= 0 ? diasrestantes : 0) + " días,estos son los dias transcurridos " + diastranscurridos;
          let color = diasrestantes >= (diastotales * 0.6) ? "darkseagreen" : (diasrestantes >= (diastotales * 0.3) ? "khaki" : "coral");

          tabla[i].color = color;
          tabla[i].daysleft = texto;

        }

        this.tableFilter = tabla;
        this.totalRequests = resp.count;
        this.message = resp.message + " de " + this.totalRequests + " Resultados";
        setTimeout(() => {
          let loading = document.querySelector('.loading');
          loading.classList.remove('loading--show');

        }, 200);
      });

    this.lastfilters = {
      finaldate: formattedDate + "",
      texttosearch: "" + " ",
      selectedfilter: "" + " ",
      pagenumber: `${this.pageNumberPaginator}`,
      pagination: `${this.pageSizePaginator}`
    }


  }

  public async semaforizacion(fecha: any): Promise<void> {
      fecha = formatDate(new Date(fecha), 'yyyy-MM-dd', 'en')

      let respuesta = await lastValueFrom(this.trackingService.getRemainingdays(
        fecha,
        '7073DE5F-C45F-4CD9-937B-FAA155C6FD22'
      ));

      return respuesta;

  }
  public async semaforizacionlista(fecha: any): Promise<void> {
    fecha = formatDate(new Date(fecha), 'yyyy-MM-dd', 'en')

    let respuesta = await lastValueFrom(this.trackingService.getRemainingdays(
      fecha,
      '7073DE5F-C45F-4CD9-937B-FAA155C6FD22'
    ));

    return respuesta;

  }

  public validar(id: any): void {
    localStorage.setItem("procedure", id + "");
    localStorage.setItem("source", "validation");
    this.router.navigateByUrl(ROUTES.AUT_TITULOS + "/" + ROUTES.Validation)
  }

  public statechange(): void {

    let selector=this.validatorForm.get('selector').value;

    if(selector=='filed_date')

    {
      this.seachbar=false;
      this.datebar=true;
    }
    else {
      this.seachbar=true;
      this.datebar=false;
    }

  }

  public async getDashboard(): Promise<void> {
    setTimeout(() => {
      let loading = document.querySelector('.loading');
      loading.classList.add('loading--show');

    }, 200);
    let role: string = localStorage.getItem('Role');
    let selector = this.validatorForm.get('selector').value;
    let text = "";


    if (selector == 'filed_date') {
      if (this.validatorForm.get('fecha').value != "") {
        text = this.validatorForm.get('fecha').value;
      }
    } else {
      text = this.validatorForm.get('textfilter').value;
    }

    this.validatorForm.get('pageNumber').setValue(1);
let fechas:any=""
    if (selector == "tiempo") {
      try {
        const numero:Number=Number.parseInt(text)

        fechas = await lastValueFrom(this.trackingService.getCreationDate(
          text,
          '7073DE5F-C45F-4CD9-937B-FAA155C6FD22'
        ));

      }
      catch (Exception )
      {
        this.popupAlert.infoAlert(`Por favor, ingrese un valor numerico.`, 4000);
        setTimeout(() => {
          let loading = document.querySelector('.loading');
          loading.classList.remove('loading--show');

        }, 200);
        return ;
      }



    }
    if(fechas!="")
    {
      text=fechas.data.diasNatural.substring(0,10);

    }


    let date: Date = new Date(Date.now());

    // Get year, month, and day part from the date
    let year = date.toLocaleString("default", {year: "numeric"});
    let month = date.toLocaleString("default", {month: "2-digit"});
    let day = date.toLocaleString("default", {day: "2-digit"});
    this.pageNumberPaginator = 1;
    this.paginator.firstPage();
    // Generate yyyy-mm-dd date string
    let formattedDate = year + "-" + month + "-" + day;
    this.requestService.getDashboardValidation(
      formattedDate + "",
      (text == null || text == "") ? " " : text,
      (selector == null || selector == "") ? " " : selector,
      `${this.pageNumberPaginator}`,
      `${this.pageSizePaginator}`, role).subscribe(async resp => {
      let tabla = resp.data;


      let listasolicitudes: string[] =[]


      for (const element of tabla)
      {
        listasolicitudes.push(element.fileddate.substring(0,10)+"");
      }


      let respuesta = await lastValueFrom(this.trackingService.getRemainingdayslista(
        {
          initialDates: listasolicitudes,
          idTramite: "7073DE5F-C45F-4CD9-937B-FAA155C6FD22"
        }
      ));
      for (let i = 0; i <tabla.length ; i++) {
        let diasrestantes = respuesta.data[i].personaNatural.diasRestantes;
        let diastranscurridos = respuesta.data[i].personaNatural.diasHabilesTanscurridos;
        let diastotales = respuesta.data[i].personaNatural.totalANS;
        let texto = "Quedan " + (diasrestantes >= 0 ? diasrestantes : 0) + " días,estos son los dias transcurridos " + diastranscurridos;
        let color = diasrestantes >= (diastotales * 0.6) ? "darkseagreen" : (diasrestantes >= (diastotales * 0.3) ? "khaki" : "coral");

        tabla[i].color = color;
        tabla[i].daysleft = texto;

      }

      this.tableFilter = tabla;
      this.totalRequests = resp.count;
      this.message = resp.message + " de " + this.totalRequests + " Resultados";
      setTimeout(() => {
        let loading = document.querySelector('.loading');
        loading.classList.remove('loading--show');

      }, 200);
    });

    this.lastfilters = {
      finaldate: formattedDate + "",
      texttosearch: (text == null || text == "") ? " " : text,
      selectedfilter: (selector == null || selector == "") ? " " : selector,
      pagenumber: `${this.pageNumberPaginator}`,
      pagination: `${this.pageSizePaginator}`
    }

  }

  /**
   * Cambia la página en la tabla
   * @param e Evento del paginador
   */
  public async changePage(e: PageEvent): Promise<void> {
    setTimeout(() => {
      let loading = document.querySelector('.loading');
      loading.classList.add('loading--show');

    }, 200);


    this.pageSizePaginator = e.pageSize;
    this.pageNumberPaginator = e.pageIndex + 1;

    let role: string = localStorage.getItem('Role');

    let text = this.lastfilters.texttosearch;
    let fechas: any = ""
    if (this.lastfilters.selectedfilter == "tiempo") {
      try {
        const numero: Number = Number.parseInt(text)

        fechas = await lastValueFrom(this.trackingService.getCreationDate(
          text,
          '7073DE5F-C45F-4CD9-937B-FAA155C6FD22'
        ));

      } catch (Exception) {
        this.popupAlert.infoAlert(`Por favor, ingrese un valor numerico.`, 4000);
        setTimeout(() => {
          let loading = document.querySelector('.loading');
          loading.classList.remove('loading--show');

        }, 200);
        return;
      }


    }
    if (fechas != "") {
      text = fechas.data.diasNatural.substring(0, 10);

    }


    this.requestService.getDashboardValidation(
      this.lastfilters.finaldate + "",
      text + "",
      this.lastfilters.selectedfilter + "",
      `${this.pageNumberPaginator}`,
      `${this.pageSizePaginator}`,
      role).subscribe(async resp => {

      let tabla = resp.data;

      let listasolicitudes: string[] =[]


      for (const element of tabla)
      {
        listasolicitudes.push(element.fileddate.substring(0,10)+"");
      }


      let respuesta = await lastValueFrom(this.trackingService.getRemainingdayslista(
        {
          initialDates: listasolicitudes,
          idTramite: "7073DE5F-C45F-4CD9-937B-FAA155C6FD22"
        }
      ));
      for (let i = 0; i <tabla.length ; i++) {
        let diasrestantes = respuesta.data[i].personaNatural.diasRestantes;
        let diastranscurridos = respuesta.data[i].personaNatural.diasHabilesTanscurridos;
        let diastotales = respuesta.data[i].personaNatural.totalANS;
        let texto = "Quedan " + (diasrestantes >= 0 ? diasrestantes : 0) + " días,estos son los dias transcurridos " + diastranscurridos;
        let color = diasrestantes >= (diastotales * 0.6) ? "darkseagreen" : (diasrestantes >= (diastotales * 0.3) ? "khaki" : "coral");

        tabla[i].color = color;
        tabla[i].daysleft = texto;

      }
      this.letGoTop();
      this.tableFilter = tabla;
      this.totalRequests = resp.count;
      this.message = resp.message + " de " + this.totalRequests + " Resultados";
      setTimeout(() => {
        let loading = document.querySelector('.loading');
        loading.classList.remove('loading--show');

      }, 200);

    });
  }
  public letGoTop(): void {
    let mainInter = document.getElementById('interno');

    mainInter?.scroll({
      top: 10,
      left: 10,
      behavior: "smooth",
    });
    let main = document.getElementById('content-layout-id');
    main?.scroll({
      top: 10,
      left: 10,
      behavior: "smooth",
    });


  }


}
