import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {ControlContainer} from "@angular/forms";

import {ArchiveService, PopUpService} from "@core-app/services";
import {DocumentsService} from "@core-app/services";

/**
 * Component que permite visualizar documentos cargados en el trámite
 */
@Component({
  selector: 'app-attachment-viewer',
  templateUrl: './attachment-viewer.component.html',
  styleUrls: ['./attachment-viewer.component.scss']
})
export class AttachmentViewerComponent implements OnInit {


  /**
   * Archivos subidos por el ciudadano
   */
  public documentSupports: any[]

  /**
   * ¿Todos los documentos cumplen?
   */
  @Output() documents = new EventEmitter<boolean>();

  /**
   * Formulario padre para controlar el estado de los documentos
   */
  public attachmentform: any;

  /**
   * Icono de previsualizacion en la misma pantalla
   */
  public readonly urlIconActualWindow: string = 'https://cdn-icons-png.flaticon.com/512/2889/2889358.png';

  /**
   * Icono de previsualizacion en otra pestaña
   */
  public readonly urlIconExternalWindow: string = 'https://cdn-icons-png.flaticon.com/512/337/337946.png';

  constructor(private popupAlert: PopUpService,
              public archiveService: ArchiveService,
              private documentService: DocumentsService, private controlContainer: ControlContainer) {

    this.documentService.getDocumentsByIdRequest(localStorage.getItem('procedure')).subscribe(resp => {

      this.documentSupports = resp.data;
  this.attachmentform.get('documentstate').setValue(this.documentSupports );
  this.validateDocuments(this.documentSupports)

    });

  }

  ngOnInit(): void {
    this.attachmentform = this.controlContainer.control;
    this.attachmentform = this.attachmentform.controls['attachmentform'];
  }

  public statechange(id: number): void {
    for (const element of this.documentSupports) {
      if (element.idDocumentTypeProcedureRequest == id) {
        element.is_valid = !element.is_valid;
        this.attachmentform.get('documentstate').setValue(this.documentSupports );
        break;
      }
    }
    console.log(this.documentSupports);
    this.validateDocuments(this.documentSupports);
  }

  public validateDocuments(documents: any[]): void{

    let flag = true;

    documents.forEach(element => {

      if (!element.is_valid) {
        flag = false;
      }
    });

    if (documents.length == 0) {
      flag = false;
    }
    this.documents.emit(flag);
    if (flag) {
      console.log("All the documents are valid-att");
    }else{
      console.log("All the documents aren't valid-att");
    }
    console.log(documents);
  }

}
