<form #contentWrapper [formGroup]="validatorForm" class="mt-3">
  <app-advance-line [step]="stepAdvanceLine" [currentProgress]="currentProgressAdvanceLine"
                    [source]="'validador'"></app-advance-line>

  <br><br>
  <h3>Trámite: Registro y autorización de titulos en el area de la salud.</h3>

  <h3>Bandeja de entrada</h3>

  <div class="row   align-items-center mx-auto mt-5">
    <ul class="nav nav-tabs ">
      <li class="nav-item">
        <a class="nav-link active">Por Asignar</a>
      </li>
    </ul>
    <div class="border">

      <br> <br>
      <p>Filtrar por:</p>
      <div class="row mt-3 entradas-de-texto-govco ">

        <div class="mt-3 col-xxl-3 col-md-3 col-lg-3 col-sm-3 col-xs-3">
          <select class="form-select" id="selector" aria-label="Default select example"
                  formControlName="selector" (change)="statechange()">
            <option value="" selected disabled>Seleccione</option>
            <option value="filed_number">ID Solicitud</option>
            <option value="IdNumber">No. Doc de Identidad</option>
            <option value="AplicantName">Nombre Solicitante</option>
            <option value="TitleType">Origen Título</option>
            <option value="filed_date">Fecha de Registro Solicitud</option>
            <option value="status">Estado de la Solicitud</option>
            <option value="tiempo">Tiempo de Atención Restante</option>
          </select>
        </div>
        <div class="mt-3 col-xxl-3 col-md-3 col-lg-3 col-sm-3 col-xs-3">
          <div *ngIf="seachbar">
            <input type="text" id="textfilter" formControlName="textfilter" placeholder="Ingrese Su Busqueda"/>
          </div>
          <div *ngIf="datebar">
            <input formControlName="fecha" class="form-control gov-co-form-control" type="date"
                   id="fecha-busqueda"  aria-describedby="fecha-note" data-toggle="tooltip" max="{{this.actualday}}"
                   data-placement="top" />
          </div>
        </div>
        <div class="mt-3 col-xxl-3 col-md-3 col-lg-3 col-sm-3 col-xs-3">
          <div>
            <button type="button" style="margin-left: 1rem;" (click)="getDashboard()" class="btn-govco fill-btn-govco">
              Buscar
            </button>
          </div>
        </div>
      </div>

      <br><br>

      <div style="display: grid;place-items: flex-end" >{{this.message}}</div>
      <div class="row">
        <div class="table col-12">
          <div class="table-responsive">
            <table class="table table-striped " aria-describedby="tabla-funcionarios">

              <tr>
                <th [scope]="">ID Solicitud</th>
                <th [scope]="">No. Doc de Identidad</th>
                <th [scope]="">Nombre Solicitante</th>
                <th [scope]="">Origen Título</th>
                <th [scope]="">Fecha de Registro Solicitud</th>
                <th [scope]="">Estado de la Solicitud</th>
                <th [scope]="">Acciones Permitidas</th>
                <th [scope]=""> Tiempo de Atención Restante</th>


              </tr>
              <tbody>
              <tr *ngFor="let item of tableFilter ; let i= index">
                <td>{{item.idfiled}}</td>
                <td> {{item.idnumber}}  </td>
                <td>{{item.aplicantname}}</td>
                <td>{{item.titletype}}</td>
                <td>{{item.fileddate | date: 'dd/MM/yyyy'}}</td>
                <td *ngIf="item.statusstring != null">{{item.statusstring}}</td>
                <td *ngIf="item.validation" class="text-justify">
                  <a (click)="validar(item.idprocedure)"> <u class="govco-edit-1">Validar</u> </a>
                </td>
                <td *ngIf="!item.validation"></td>
                <td style="background-color:{{item.color}};">{{this.item.daysleft}}</td>


              </tr>
              </tbody>
            </table>
          </div>
        </div>


        <mat-paginator length="{{totalRequests}}"
                       [pageSizeOptions]="[5, 10, 25, 50, 100]"
                       (page)="changePage($event) "
                       aria-label="Select page"
                       showFirstLastButtons="true"
                       pageSize="{{pageSizePaginator}}">
        </mat-paginator>
      </div>
    </div>
  </div>
  <div class="loading loading--show">
    <div class="spin"></div>
  </div>


</form>
